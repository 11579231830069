import { WorkerSearch } from "src/app/core/interfaces/worker-search.interface";
import { Worker } from "src/app/core/interfaces/worker.interface";

export class GetAllWorkers {
	static readonly type = "[Dispatch] Get All Workers";
	constructor(
		public workerSearch: WorkerSearch
	) {}
}

export class GetOrderDetails {
	static readonly type = "[Dispatch] Get Order Details";
	constructor(
		public orderId: string
	) {}
}

export class SendJobAssignments {
	static readonly type = "[Dispatch] Post Job Assignments";
	constructor(
		public orderId: string,
		public dispatcherComments: string,
		public hasAgreedCheckCertifications: boolean,
		public hasAgreedAssignedSameStartTime: boolean,
		public callback: (error) => void
	) {}
}

export class SelectJob {
	static readonly type = "[Dispatch] Select Job";
	constructor(public index: number) { }
}

export class AssignWorker {
	static readonly type = "[Dispatch] Assign Worker";
	constructor(public worker: Worker) { }
}

export class UnAssignWorker {
	static readonly type = "[Dispatch] Unassign Worker";
	constructor(public worker: Worker) { }
}

export class AssignWorkerOverrideRule {
	static readonly type = "[Dispatch] Add Worker to allAssignmentsOver ";
	constructor(
		public worker: Worker,
		public comments: string,
	) { }
}

export class ResetWorkerAndOverr {
	static readonly type = "[Dispatch] Reset Wokers and Override Rules";
	constructor() { }
}

export class LoadDispatchOrdersList {
	static readonly type = "[Dispatch] List";
	constructor(public skipCount: number, public maxResult: number, public filters?: any) { }
}

export class ReplaceWorker {
	public static readonly type = "[Dispatch] replace worker";
	constructor(
		public dispatchOrderId: string,
		public dispatchJobRequestId: string,
		public workerId: number,
		public replacedWorkerId: number,
		public doGetDetail: boolean,
		public hasAgreedCheckCertifications: boolean,
		public hasAgreedAssignedSameStartTime: boolean,
		public hasAgreedAlreadyAssigned: boolean,
		public replacementDate: string,
		public isQuitReplacement: boolean,
		public callback: (error) => void
	) { }
}

export class CancelReplaceWorker {
	public static readonly type = "[Dispatch] cancel replace worker";
	constructor(
		public jobAssignmentId: string,
		public doGetDetail: boolean,
		public callbackSuccess: () => void,
		public callbackError: (error) => void
	) { }
}

export class UpdateAssignmentNote {
	public static readonly type = "[Dispatch] update assignment note";
	constructor(
		public dispatchOrderId: string,
		public workerId: number,
		public note: string
	) {}
}

export class BumpWorker {
	public static readonly type = "[Dispatch] bump worker";
	constructor(
		public dispatchOrderId: string,
		public dispatchJobRequestId: string,
		public workerId: number,
		public bumpedWorkerId: number,
		public bumpedDate: boolean,
		public hasAgreedCheckCertifications: boolean,
		public hasAgreedAssignedSameStartTime: boolean,
		public hasAgreedAlreadyAssigned: boolean,
		public callback: (error) => void
	) { }
}

export class CancelBumpWorker {
	public static readonly type = "[Dispatch] cancel bump worker";
	constructor(
		public jobAssignmentId: string,
		public callbackSuccess: () => void,
		public callbackError: (error) => void,
	) {}
}